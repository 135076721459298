import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    function closeVideo() {
      store.commit("updateVideoFlag", false);
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        class: "app",
        onClick: closeVideo
      }, [_createVNode(_component_router_view)]);
    };
  }
};