import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";

const state = {
    lang: "zh-CN",
    videoFlag: false,
};

const mutations = {
    updateLang(state, value) {
        state.lang = value;
    },
    updateVideoFlag(state, value) {
        state.videoFlag = value;
    },
};
const actions = {};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
